import Pill from "components/Pill"
import gsap from "gsap"
import useCanHover from "library/canHover"
import { fresponsive } from "library/fullyResponsive"
import useAnimation from "library/useAnimation"
import { useResponsivePixels } from "library/viewportUtils"
import { Application, Graphics } from "pixi.js"
import { type RefObject, useEffect, useMemo, useRef, useState } from "react"
import styled, { css } from "styled-components"
import colors from "styles/colors"

/**
 * creates and returns a stable PIXI application
 * @param insertInto element to insert the canvas into
 */
const useApp = (insertInto: RefObject<HTMLElement | null>) => {
	const app = useMemo(() => new Application(), [])
	const [isReady, setIsReady] = useState(false)

	useEffect(() => {
		if (!insertInto.current) return

		let hasInit = false
		let isMounted = true

		app
			.init({
				resizeTo: insertInto.current,
				backgroundAlpha: 0,
				antialias: true,
				resolution: window.devicePixelRatio,
				autoDensity: true,
			})
			.then(() => {
				// if the component is unmounted immediately, we don't want to insert the canvas
				if (isMounted) {
					hasInit = true
					setIsReady(true)
					insertInto.current?.appendChild(app.canvas)
				} else {
					app.destroy()
				}
			})

		return () => {
			isMounted = false
			const canvas = app.canvas
			if (hasInit) app.destroy()
			if (Array.from(insertInto.current?.children || []).includes(canvas)) {
				insertInto.current?.removeChild(canvas)
			}
		}
	}, [app, insertInto])

	return isReady ? app : null
}

export default function WobblyLines() {
	const wrapperRef = useRef<HTMLDivElement>(null)
	const pillRef = useRef<HTMLDivElement>(null)
	const app = useApp(wrapperRef)
	const [isWithinBounds, setIsWithinBounds] = useState(false)

	/**
	 * buffer zone around the canvas to prevent unwanted clipping
	 * must be larger than the bubble size
	 */
	const PADDING = useResponsivePixels(100)
	/**
	 * half the width of the bubble zone, including the curves on either side
	 */
	const HALF_BUBBLE_SIZE_X = useResponsivePixels(194 / 2)
	/**
	 * half the height of the bubble zone
	 */
	const HALF_BUBBLE_SIZE_Y = useResponsivePixels(126 / 2)
	/**
	 * half the width of the flat area on the top and bottom of the bubble
	 */
	const HALF_BUBBLE_DEAD_ZONE = useResponsivePixels(45 / 2)
	/**
	 * if a line is in the middle of the bubble, how far should it move up/down?
	 * this will be clamped to the bubble size
	 */
	const BUBBLE_LINE_SHIFT = useResponsivePixels(40)

	const hasMouse = useCanHover()
	const ease = hasMouse ? "elastic.out(1.5,0.3)" : "power3.out"
	const duration = hasMouse ? 3 : 0.4

	useAnimation(
		() => {
			// gsap.to(pillRef.current, {
			// 	scale: isWithinBounds ? 1 : 0,
			// 	pointerEvents: isWithinBounds ? "all" : "none",
			// 	duration: isWithinBounds ? 1.6 : 0.3,
			// 	ease: isWithinBounds ? ease : "power2.in",
			// })
		},
		[],
		{ kill: true },
	)

	useEffect(() => {
		if (!app) return
		// if the app is destroyed, this will be undefined
		if (typeof app.ticker === "undefined") return

		/**
		 * create 30 lines to draw with
		 */
		const graphics = Array.from({ length: 30 }, (_, i) => {
			return new Graphics()
		})
		/**
		 * when lines move, we want to animate them smoothly
		 * this object will store the smooth y position of each line
		 */
		const shiftedHeights: Record<string, number | undefined> =
			Object.fromEntries(graphics.map((_, i) => [i, undefined]))
		const heightUpdaters = graphics.map((_, i) =>
			gsap.quickTo(shiftedHeights, i.toString(), {
				ease,
				duration,
			}),
		)
		const mousePosition: { x: number; y: number } = { x: 0, y: 0 }

		let lastFrame = ""

		const updateLines = () => {
			// some naive optimization to avoid re-rendering the same lines
			const heightsCode = Object.entries(shiftedHeights)
				.filter(([key]) => !key.startsWith("_"))
				.map(([key, value]) => {
					return `${key}:${value}`
				})
				.join(",")
			const mouseCode = `${mousePosition.x},${mousePosition.y}`
			const newFrameCode = `${heightsCode},${mouseCode}`

			if (lastFrame === newFrameCode) {
				return
			}
			lastFrame = newFrameCode

			for (const line of graphics) {
				const index = graphics.indexOf(line)
				const canvasHeight =
					(wrapperRef.current?.clientHeight ?? 0) - PADDING * 2
				const linesGap = canvasHeight / (graphics.length - 1)

				/**
				 * the base y position of the line
				 */
				const lineY = PADDING + linesGap * index

				const isAboveMouse = lineY < mousePosition.y

				const shiftedY = isAboveMouse
					? lineY - BUBBLE_LINE_SHIFT
					: lineY + BUBBLE_LINE_SHIFT

				// clamp the y position to the size of the bubble
				const clampedY = Math.max(
					mousePosition.y - HALF_BUBBLE_SIZE_Y,
					Math.min(mousePosition.y + HALF_BUBBLE_SIZE_Y, shiftedY),
				)

				// if the line is far away (vertically), we'll use its base position
				const isAffectedByMouse =
					Math.abs(lineY - mousePosition.y) < HALF_BUBBLE_SIZE_Y

				heightUpdaters[index]?.(isAffectedByMouse ? clampedY : lineY)
			}
		}

		const sharedProps = {
			ease,
			duration,
		}
		const pillMoverX = gsap.quickTo(pillRef.current, "x", {
			onUpdate: () => {
				gsap.set(pillRef.current, { xPercent: -50 })
			},
			...sharedProps,
		})
		const pillMoverY = gsap.quickTo(pillRef.current, "y", {
			onUpdate: () => {
				gsap.set(pillRef.current, { yPercent: -50 })
			},
			...sharedProps,
		})
		const mouseMoverX = gsap.quickTo(mousePosition, "x", { ...sharedProps })
		const mouseMoverY = gsap.quickTo(mousePosition, "y", {
			onUpdate: updateLines,
			...sharedProps,
		})

		/**
		 * logic to draw the lines in place on each frame
		 */
		const onFrame = () => {
			for (const line of graphics) {
				const index = graphics.indexOf(line)
				const canvasHeight =
					(wrapperRef.current?.clientHeight ?? 0) - PADDING * 2

				/** gap between each line */
				const linesGap = canvasHeight / (graphics.length - 1)
				/**
				 * the base y position of the line
				 */
				const lineY = PADDING + linesGap * index
				/**
				 * the y position of the line after it has been shifted by the mouse bubble
				 */
				const shiftedLineY = shiftedHeights[index]

				line.clear()
				line.moveTo(PADDING, lineY)

				if (shiftedLineY && mousePosition) {
					// left side of bubble
					line.lineTo(mousePosition.x - HALF_BUBBLE_SIZE_X, lineY)

					// curve to top side of bubble
					const midX =
						mousePosition.x -
						HALF_BUBBLE_DEAD_ZONE -
						(HALF_BUBBLE_SIZE_X - HALF_BUBBLE_DEAD_ZONE) / 2
					line.bezierCurveTo(
						// control point 1
						midX,
						lineY,
						// control point 2
						midX,
						shiftedLineY,
						// final pos
						mousePosition.x - HALF_BUBBLE_DEAD_ZONE,
						shiftedLineY,
					)

					// top side of bubble
					line.lineTo(mousePosition.x + HALF_BUBBLE_DEAD_ZONE, shiftedLineY)

					// curve to right side of bubble
					const midX2 =
						mousePosition.x +
						HALF_BUBBLE_DEAD_ZONE +
						(HALF_BUBBLE_SIZE_X - HALF_BUBBLE_DEAD_ZONE) / 2
					line.bezierCurveTo(
						// control point 1
						midX2,
						shiftedLineY,
						// control point 2
						midX2,
						lineY,
						// final pos
						mousePosition.x + HALF_BUBBLE_SIZE_X,
						lineY,
					)
				}

				// finish line and draw
				line.lineTo(app.canvas.width - PADDING, lineY)
				line.stroke({ color: 0x6563ea, width: 1 })
			}
		}

		/**
		 * update the mouse position and pill position (which will then update the lines)
		 */
		const mouseMove = (e: { clientX: number; clientY: number }) => {
			const bounds = wrapperRef.current?.getBoundingClientRect()
			if (!bounds) return

			const x = e.clientX - bounds.left
			const y = e.clientY - bounds.top

			const isWithinBounds =
				x >= PADDING &&
				x <= bounds.width - PADDING &&
				y >= PADDING &&
				y <= bounds.height - PADDING

			setIsWithinBounds(isWithinBounds)

			pillMoverX(x - PADDING)
			pillMoverY(y - PADDING)

			mouseMoverX(x)
			mouseMoverY(y)
		}

		/**
		 * if we don't know the mouse position or if there is no mouse, we'll reset it to the center
		 */
		const resetMousePosition = () => {
			const bounds = wrapperRef.current?.getBoundingClientRect()
			if (!bounds) return

			const x = bounds.width / 2
			const y = bounds.height / 2

			pillMoverX(x - PADDING)
			pillMoverY(y - PADDING)

			mouseMoverX(x)
			mouseMoverY(y)

			setIsWithinBounds(true)
		}

		/**
		 * set up pixi
		 */
		app.stage.addChild(...graphics)
		app.ticker.add(onFrame)
		resetMousePosition()

		const touchMove = (e: TouchEvent) => {
			e.preventDefault()
			const touch = e.touches[0]
			if (touch) mouseMove({ clientX: touch.clientX, clientY: touch.clientY })
		}

		const parent = pillRef.current?.parentElement

		window.addEventListener("mousemove", mouseMove)
		window.addEventListener("resize", resetMousePosition)
		if (parent) parent.addEventListener("touchmove", touchMove)
		return () => {
			gsap.killTweensOf(pillRef.current)
			gsap.killTweensOf(mousePosition)
			window.removeEventListener("mousemove", mouseMove)
			window.removeEventListener("resize", resetMousePosition)
			if (parent) parent.removeEventListener("touchmove", touchMove)

			// optional chaining is important here, since the ticker might be undefined
			app.ticker?.remove(onFrame)
			for (const line of graphics) {
				app.stage?.removeChild(line)
				line.destroy()
			}
		}
	}, [
		app,
		PADDING,
		HALF_BUBBLE_DEAD_ZONE,
		BUBBLE_LINE_SHIFT,
		HALF_BUBBLE_SIZE_X,
		HALF_BUBBLE_SIZE_Y,
		duration,
		ease,
	])

	return (
		<>
			<Wrapper>
				<Canvas ref={wrapperRef} />
			</Wrapper>
			<WhitePill forwardRef={pillRef} />
		</>
	)
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: clip visible;
`

const Canvas = styled.div`
  ${fresponsive(css`
    position: absolute;
    inset: -100px;
    z-index: 1;
    pointer-events: none;
  `)}
`

const WhitePill = styled(Pill)`
  position: absolute;
  background-color: ${colors.silver05};
  cursor: none;
  z-index: 2;

  svg {
    path {
      fill: ${colors.blue05};
    }

    ${fresponsive(css`
      width: 60px;
    `)}
  }

  ${fresponsive(css`
    top: 0;
    left: 0;
    width: 100px;
    height: 44px;
  `)}
`
