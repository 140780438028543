import Footer from "components/Footer"
import Header from "components/Header"
import ClientOnly from "library/ClientOnly"
import { useBackButton } from "library/Loader/TransitionUtils"
import Scroll from "library/Scroll"
import { useTrackPageReady } from "library/pageReady"
import useTrackFrameTime from "library/useTrackFrameTime"
import styled, { createGlobalStyle, css } from "styled-components"
import colors from "styles/colors"
import { ColorStyle } from "styles/colors"
import textStyles from "styles/text"
import Banner from "./Banner"
import Preloader from "./Preloader"
import Transition from "./Transition"

interface LayoutProps {
	children: React.ReactNode
}

export default function Layout({ children }: LayoutProps) {
	useTrackPageReady()
	useBackButton()
	useTrackFrameTime()

	return (
		<>
			<ColorStyle />
			<Transition />
			<Preloader />
			<GlobalStyle />
			<ScrollIndex>
				<Banner />
				<Header />
				<Main>
					<ClientOnly>{children}</ClientOnly>
				</Main>
				<Footer position="static" />
			</ScrollIndex>
			<Footer position="fixed" />
		</>
	)
}

const ScrollIndex = styled(Scroll)`
  z-index: 2;
  pointer-events: none;

  & #smooth-content > * {
    pointer-events: auto;
  }
`

const Main = styled.main`
  overflow: clip visible;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    inset: 0 0 24px;
    background: ${colors.blue05};
    z-index: -1;
  }
`

const globalCss = css`
  /* default text styles */
  html {
    color: ${colors.blue01};
    font-family: sans-serif;
    ${textStyles.bodyR}
    background: ${colors.blue05};
  }

  * {
    /* need this so that fonts match figma */
    text-rendering: geometricprecision;
    -webkit-font-smoothing: antialiased;
  }

  /** restore default focus states for elements that need them */
  *:focus-visible {
    outline: 2px solid #00f8;
  }

  .grecaptcha-badge {
    visibility: hidden;
  }
`

const GlobalStyle = createGlobalStyle`${globalCss}`
