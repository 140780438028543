import gsap from "gsap"
import DrawSVGPlugin from "gsap/DrawSVGPlugin"
import { ReactComponent as LogoSVG } from "images/global/logo-minimal.svg"
import { fresponsive } from "library/fullyResponsive"
import useAnimation from "library/useAnimation"
import { useRef } from "react"
import styled, { css } from "styled-components"
import colors from "styles/colors"

gsap.registerPlugin(DrawSVGPlugin)

export default function Pill({
	className = "",
	forwardRef,
	white = false,
}: {
	forwardRef?: React.RefObject<HTMLDivElement>
	className?: string
	white?: boolean
}) {
	const internal = useRef<HTMLDivElement>(null)
	const ref = forwardRef ?? internal

	useAnimation(
		() => {
			const tl = gsap.timeline({
				repeat: -1,
			})

			tl.from("path", {
				clipPath: "inset(0% 100% 0% 0%)",
			})
			tl.to("path", {
				clipPath: "inset(0% 0% 0% 100%)",
				delay: 3,
			})
		},
		[],
		{
			scope: ref,
		},
	)

	return (
		<Wrapper className={className} ref={ref} $white={white}>
			<Logo />
		</Wrapper>
	)
}

const Wrapper = styled.div<{
	$white: boolean
}>`
  background-color: ${colors.blue01};
  border-radius: 99vw;
  display: grid;
  place-items: center;

  ${fresponsive(css`
    width: 64px;
    height: 28px;
  `)}

  ${({ $white }) =>
		$white &&
		css`
      background-color: white;

      path {
        fill: ${colors.blue01};
      }
    `}
`

const Logo = styled(LogoSVG)`
  ${fresponsive(css`
    height: auto;
    width: 38px;
  `)}
`
