import UniversalLink, {
	type UniversalLinkProps,
} from "library/Loader/UniversalLink"
import { fresponsive } from "library/fullyResponsive"
import styled, { css } from "styled-components"
import colors from "styles/colors"
import textStyles from "styles/text"

export default function Primary({
	children,
	className,
	...props
}: UniversalLinkProps) {
	return (
		<Wrapper className={className} {...props}>
			<Inner>
				<ChildrenOverflow>
					<Children>{children}</Children>
					<Children>{children}</Children>
				</ChildrenOverflow>
			</Inner>
		</Wrapper>
	)
}

const ChildrenOverflow = styled.div`
  overflow: clip;
  display: flex;
  flex-direction: column;

  ${fresponsive(css`
    height: 17px;
    gap: 17px;
  `)}
`

const Children = styled.span`
  transition: transform 0.25s;
`

const Inner = styled.div`
  width: fit-content;
  min-width: 100%;
  background-color: ${colors.silver05};
  border-radius: 99vw;
  ${textStyles.bodyL}
  color: ${colors.blue02};
  transition: background-color 0.5s;
  white-space: nowrap;

  ${fresponsive(css`
    padding: 15px 30px;
    box-shadow:
      0 1px 4px 0 rgb(0 0 0 / 45%),
      0 -1px 3px 0 #b2b0ff inset,
      0 0 0 1px #3c39b9;
  `)}
`

const Wrapper = styled(UniversalLink)`
  width: fit-content;
  background-color: ${colors.lavender01};
  border-radius: 99vw;
  transition: background-color 0.5s;

  &:hover {
    background-color: ${colors.lavender03};

    ${Inner} {
      background-color: ${colors.lavender05};
    }

    ${Children} {
      transform: translateY(-200%);
    }
  }

  ${fresponsive(css`
    padding: 8px;
  `)}
`
