import gsap from "gsap"
import { useRegisterTransition } from "library/Loader/TransitionUtils"
import { useRef } from "react"
import styled, { css } from "styled-components"

import { ReactComponent as TransitionPulseSVG } from "images/global/TransitionPulse.svg"
import { fresponsive } from "library/fullyResponsive"
import colors from "styles/colors"

const duration = 0.5

export default function Transition() {
	const wrapperRef = useRef<HTMLDivElement>(null)

	const slideIn = () => {
		// show before animating
		gsap.set(wrapperRef.current, {
			autoAlpha: 1,
		})

		gsap.fromTo(
			wrapperRef.current,
			{
				y: "100lvh",
				borderRadius: "200px 200px 0 0",
			},
			{
				y: "0lvh",
				duration,
				ease: "power3.out",
				borderRadius: "0px 0px 0 0",
			},
		)

		const path = wrapperRef.current?.querySelector(".animate") ?? null
		const svg = wrapperRef.current?.querySelector("svg") ?? null

		gsap.fromTo(
			svg,
			{
				y: "-100lvh",
			},
			{
				y: "0lvh",
				duration,
				ease: "power3.out",
			},
		)

		gsap.fromTo(
			path,
			{
				drawSVG: "0% 0%",
			},
			{
				drawSVG: "0% 100%",
				delay: duration / 2,
				duration: duration * 0.9,
				ease: "power3.inOut",
			},
		)
	}

	const slideOut = () => {
		gsap.to(wrapperRef.current, {
			y: "100lvh",
			borderRadius: "200px 200px 0 0",
			duration,
			ease: "power3.in",
		})

		const svg = wrapperRef.current?.querySelector("svg") ?? null

		gsap.to(svg, {
			y: "-100lvh",
			duration,
			ease: "power3.in",
		})

		// hide when done
		gsap.set(wrapperRef.current, {
			autoAlpha: 0,
			delay: duration,
		})
	}

	useRegisterTransition("slide", {
		in: slideIn,
		out: slideOut,
		inDuration: duration,
		outDuration: duration,
	})

	return (
		<Wrapper ref={wrapperRef}>
			<Pulse />
		</Wrapper>
	)
}

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  translate: 0 100lvh;
  background-color: ${colors.blue02};
  z-index: 100;
  pointer-events: none;
  display: grid;
  place-items: center;
  overflow: clip;
  opacity: 0;
`

const Pulse = styled(TransitionPulseSVG)`
  ${fresponsive(css`
    width: 168px;
  `)}
`
