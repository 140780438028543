import { DesktopTabletOnly, MobileOnly } from "library/breakpointUtils"
import DesktopTablet from "./DesktopTablet"
import Mobile from "./Mobile"

export default function Header() {
	return (
		<>
			<DesktopTabletOnly>
				<DesktopTablet />
			</DesktopTabletOnly>
			<MobileOnly>
				<Mobile />
			</MobileOnly>
		</>
	)
}
