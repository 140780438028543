import { useLocation } from "@reach/router"
import { ReactComponent as LogoSVG } from "images/global/Logo.svg"
import UniversalLink from "library/Loader/UniversalLink"
import { fmobile, fresponsive, ftablet } from "library/fullyResponsive"
import { pathnameMatches } from "library/functions"
import styled, { css } from "styled-components"
import colors from "styles/colors"
import { desktopBreakpoint } from "styles/media"
import textStyles from "styles/text"
import links from "utils/links"
import Link from "./Buttons/Link"
import Primary from "./Buttons/Primary"
import WobblyLines from "./Lines"

export default function Footer({ position }: { position: "fixed" | "static" }) {
	const pathname = useLocation().pathname

	/**
	 * links where the footer should display inline, rather than fixed
	 */
	const staticRoutes = [links.requestDemo]

	const isStatic = staticRoutes.some((route) =>
		pathnameMatches(pathname, route),
	)

	const primary = isStatic ? "static" : "fixed"

	if (primary === "fixed" && position === "static") return <Spacer />
	if (primary === "static" && position === "fixed") return null

	return (
		<Wrapper position={position}>
			<Inner>
				<Top>
					<LogoWrapper>
						<UniversalLink to={links.home}>
							<Logo />
						</UniversalLink>
						<Copyright>© 2024 Impilo, Inc.</Copyright>
					</LogoWrapper>
					<LinksButton>
						<Links>
							<Link to={links.solutions}>Our Solutions</Link>
							<Link to={links.about}>About Us</Link>
							<Link to={links.blog}>Blog</Link>
							<Link to={links.docs}>Docs</Link>
							<Link to={links.careers}>Careers</Link>
							<Link to={links.contact}>Contact Us</Link>
						</Links>
						<Primary to={links.requestDemo}>Request Demo</Primary>
					</LinksButton>
				</Top>
				<LinesWrapper>
					<WobblyLines />
				</LinesWrapper>
				<Info>
					<Address style={{ gridArea: "address" }}>
						2275 Bridge Street Philadelphia, PA, 19137
					</Address>
					<UniversalLink style={{ gridArea: "phone" }} to={links.phone}>
						(202) 838-5839
					</UniversalLink>
					<UniversalLink style={{ gridArea: "privacy" }} to={links.privacy}>
						Privacy Policy
					</UniversalLink>
					<UniversalLink style={{ gridArea: "terms" }} to={links.terms}>
						Terms and Conditions
					</UniversalLink>
					<UniversalLink style={{ gridArea: "linkedin" }} to={links.linkedin}>
						Linkedin
					</UniversalLink>
				</Info>
			</Inner>
		</Wrapper>
	)
}

const Spacer = styled.div`
  ${fresponsive(css`
    height: 727px;
    max-height: 100lvh;
    pointer-events: none !important;
  `)}

  ${fmobile(css`
    height: 710px;
  `)}
`

const Wrapper = styled.footer<{
	position: "fixed" | "static"
}>`
  width: 100%;
  display: grid;
  place-items: center;
  background-color: ${colors.blue05};
  position: ${(props) => props.position};
  bottom: 0;
  left: 0;
  z-index: 1;
  overflow: clip;

  ${fresponsive(css`
    height: 727px;
  `)}

  ${fmobile(css`
    height: 710px;
  `)}
`

const Inner = styled.div`
  width: 100%;
  max-width: ${desktopBreakpoint}px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  ${fresponsive(css`
    padding: 104px 50px 80px;
    gap: 24px;
  `)}

  ${ftablet(css`
    padding: 80px 50px;
  `)}

  ${fmobile(css`
    padding: 64px 20px 19px;
    gap: 22px;
    align-items: flex-start;
  `)}
`

const Top = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;

  ${fmobile(css`
    flex-direction: column;
    align-items: flex-start;
    gap: 26px;
  `)}
`

const LogoWrapper = styled.div`
  position: relative;

  ${fmobile(css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  `)}
`

const Logo = styled(LogoSVG)`
  height: auto;

  ${fresponsive(css`
    width: 386px;
  `)}

  ${fmobile(css`
    width: 213px;
  `)}
`

const LinksButton = styled.div`
  display: flex;
  align-items: center;

  ${fresponsive(css`
    gap: 25px;
  `)}

  ${ftablet(css`
    gap: 67px;
    align-items: end;
  `)}

  ${fmobile(css`
    flex-direction: column;
    align-items: flex-start;
  `)}
`

const Links = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;

  ${fresponsive(css`
    gap: 48px;
  `)}

  ${ftablet(css`
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    margin-bottom: 21px;
  `)}

  ${fmobile(css`
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  `)}
`

const LinesWrapper = styled.div`
  position: relative;
  width: 100%;

  ${fresponsive(css`
    height: 265px;
  `)}

  ${fmobile(css`
    display: none;
  `)}
`

const Info = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  ${textStyles.bodyXS}
  color: ${colors.silver05};
  width: 100%;

  ${fresponsive(css`
    gap: 48px;
  `)}

  ${fmobile(css`
    gap: 25px;
    display: grid;
    justify-content: start;
    grid-template-areas: "privacy" "terms" "linkedin" "address" "phone";
  `)}
`

const Copyright = styled.span`
  ${textStyles.bodyXS}
  color: ${colors.silver05};
  position: absolute;
  left: 0;
  bottom: 0;

  ${fmobile(css`
    position: relative;
  `)}
`

const Address = styled.span`
  ${textStyles.bodyXS}
  color: ${colors.silver05};
`
