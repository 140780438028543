import gsap from "gsap"
import loader from "library/Loader"
import { useRegisterLoaderCallback } from "library/Loader/LoaderUtils"
import { useRef, useState } from "react"
import styled, { css } from "styled-components"
import textStyles from "styles/text"

import { ReactComponent as PreloaderGraphic } from "images/PreloaderGraphic.svg"
import { AnimatedPaths } from "library/AnimatedPaths"
import { fresponsive } from "library/fullyResponsive"
import { getResponsivePixels } from "library/viewportUtils"
import colors from "styles/colors"

export default function Preloader() {
	const wrapperRef = useRef<HTMLDivElement>(null)
	const [progress, setProgress] = useState(0)

	loader.useEventListener("progressUpdated", setProgress)

	const duration = 1

	const animateOut = () => {
		gsap.to(wrapperRef.current, {
			ease: "power3.inOut",
			yPercent: 120,
			borderRadius: "100px 100px 0 0",
			delay: duration * 0.25,
			duration,
		})
		gsap.to(wrapperRef.current?.children ?? [], {
			ease: "power3.inOut",
			y: getResponsivePixels(400),
			duration,
		})
		gsap.to(wrapperRef.current?.children ?? [], {
			ease: "power1.inOut",
			duration: duration * 0.5,
			opacity: 0,
		})

		// hide when done
		gsap.set(wrapperRef.current, {
			autoAlpha: 0,
			delay: duration,
		})
	}

	useRegisterLoaderCallback({
		callback: animateOut,
		duration,
	})

	const wholeProgress = progress.toFixed(0).padStart(2, "0")

	return (
		<Wrapper ref={wrapperRef}>
			<div
				style={{
					// safari
					willChange: "transform",
				}}
			>
				<AnimatedPaths
					selector=".animate"
					lineSpeed={[300, 300]}
					lineLength={[30, 30]}
				>
					<Graphic />
				</AnimatedPaths>
				<Text>
					{wholeProgress === "100" ? "99" : wholeProgress}
					<SubText>LOADING</SubText>
				</Text>
			</div>
		</Wrapper>
	)
}

const Text = styled.div`
  ${textStyles.h2};
  ${fresponsive(css`
    font-size: 94px;
    color: ${colors.brightBlue};
    position: fixed;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: rotateX(30deg) rotateY(337deg) rotateZ(41deg)
      translate(-21px, -32px);
    width: 146px;
    height: 137px;
  `)}
`

const SubText = styled.div`
  ${textStyles.kickerS};
  scale: 1.2 1;
`

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${colors.blue02};
  z-index: 100;
  pointer-events: none;
  display: grid;
  place-items: center;
  ${textStyles.h1};
`

const Graphic = styled(PreloaderGraphic)`
  ${fresponsive(css`
    width: 344px;
  `)}
`
