import * as Dialog from "@radix-ui/react-dialog"
import { useEventListener } from "ahooks"
import Link from "components/Buttons/Link"
import Primary from "components/Buttons/Primary"
import gsap from "gsap"
import ScrollToPlugin from "gsap/ScrollToPlugin"
import { ReactComponent as LogoSVG } from "images/global/Logo.svg"
import loader from "library/Loader"
import UniversalLink from "library/Loader/UniversalLink"
import { eases } from "library/eases"
import { fresponsive } from "library/fullyResponsive"
import { getBreakpoint } from "library/viewportUtils"
import { useRef, useState } from "react"
import styled, { css, keyframes } from "styled-components"
import colors from "styles/colors"
import textStyles from "styles/text"
import links from "utils/links"

gsap.registerPlugin(ScrollToPlugin)

export default function Mobile() {
	const [open, setOpen] = useState(false)
	const content = useRef<HTMLDivElement>(null)
	const wrapper = useRef<HTMLDivElement>(null)

	useEventListener("resize", () => {
		if (getBreakpoint() !== "mobile") setOpen(false)
	})
	loader.useEventListener("scrollTo", () => {
		setOpen(false)
	})
	loader.useEventListener("transitionCenter", () => {
		setOpen(false)
		gsap.set(content.current, { visibility: "hidden" })
	})

	return (
		<Wrapper ref={wrapper}>
			<UniversalLink to="/">
				<Logo />
			</UniversalLink>

			<Dialog.Root
				open={open}
				onOpenChange={(open) => {
					if (open && wrapper.current) {
						const currentScroll = window.scrollY
						gsap.fromTo(
							window,
							{
								scrollTo: { y: currentScroll },
							},
							{
								scrollTo: { y: wrapper.current.offsetTop },
								duration: 0.5,
								ease: "power3.out",
							},
						)
					}

					setOpen(open)
				}}
			>
				<MenuButton
					aria-label="Open Navigation"
					style={{
						pointerEvents: open ? "none" : "auto",
					}}
				>
					<Line />
					<Line />
					<Line />
				</MenuButton>
				<Overlay />
				<Content ref={content}>
					<CloseButton />
					<DarkLogoWrapper to="/">
						<DarkLogo />
					</DarkLogoWrapper>
					<CustomLink to={links.solutions}>Our Solutions</CustomLink>
					<CustomLink to={links.about}>About Us</CustomLink>
					<CustomLink to={links.blog}>Blog</CustomLink>
					<CustomLink to={links.integration}>SDK</CustomLink>
					<CustomLink to={links.docs}>Docs</CustomLink>
					<CustomLink to={links.careers}>Careers</CustomLink>
					<CustomLink to={links.contact}>Contact Us</CustomLink>
					<Primary to={links.requestDemo}>Request Demo</Primary>
					<SubLinks>
						<UniversalLink to={links.privacy}>Privacy Policy</UniversalLink>
						<UniversalLink to={links.terms}>Terms of Service</UniversalLink>
					</SubLinks>
				</Content>
			</Dialog.Root>
		</Wrapper>
	)
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`

const slideIn = keyframes`
  from {
    transform: translateY(-100lvh);
  }
  to {
    transform: translateY(0);
  }
`

const slideOut = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100lvh);
  }
`

const CloseButton = styled(Dialog.Close)`
  ${fresponsive(css`
    width: 18px;
    height: 18px;
    position: fixed;
    top: 47px;
    right: 45px;
    scale: 2;
    cursor: pointer;
  `)}
`

const SubLinks = styled.div`
  ${textStyles.bodyR};
  ${fresponsive(css`
    color: ${colors.blue02};
    display: grid;
    gap: 4px;
  `)}
`

const CustomLink = styled(Link)`
  color: ${colors.blue02};
  ${textStyles.h4};

  * {
    stroke: ${colors.blue02};
  }

  ${fresponsive(css`
    svg {
      width: 24px;
    }
  `)}

  &:hover {
    color: ${colors.blue03};

    * {
      stroke: ${colors.blue03} !important;
    }
  }
`

const Overlay = styled(Dialog.Overlay)`
  background: rgb(0 0 0 / 20%);
  position: fixed;
  inset: 0;
  z-index: 10;

  &[data-state="open"] {
    animation: ${fadeIn} 0.4s ${eases.cubic.inOut};
  }

  &[data-state="closed"] {
    animation: ${fadeOut} 0.4s ${eases.cubic.inOut};
  }
`

const DarkLogoWrapper = styled(UniversalLink)`
  ${fresponsive(css`
    width: 133px;
  `)}
`

const DarkLogo = styled(LogoSVG)`
  ${fresponsive(css`
    width: 133px;
  `)}

  * {
    fill: ${colors.blue02} !important;
  }
`

const Content = styled(Dialog.Content)`
  ${fresponsive(css`
    position: fixed;
    display: flex;
    flex-direction: column;
    gap: 40px;
    background: white;
    z-index: 11;
    top: 5px;
    left: 5px;
    width: 365px;
    padding: 40px;
    border-radius: 24px;
    max-height: calc(100dvh - 10px);
    overflow-y: auto;
  `)}

  &[data-state="open"] {
    animation: ${slideIn} 0.4s ${eases.cubic.out};
  }

  &[data-state="closed"] {
    animation: ${slideOut} 0.4s ${eases.cubic.in};
  }
`

const Wrapper = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${colors.blue05};
  will-change: transform; /* for position: fixed */
  position: relative;
  z-index: 2;

  ${fresponsive(css`
    padding: 0 15px;
    height: 113px;
  `)}
`

const Logo = styled(LogoSVG)`
  height: auto;

  ${fresponsive(css`
    width: 69.3px;
  `)}
`

const Line = styled.div`
  ${fresponsive(css`
    width: 32px;
    height: 2px;
    border-radius: 2px;
    background: ${colors.lavender06};
    transition: 0.4s ${eases.cubic.inOut};
    transition-property: rotate, translate, background, width, height;
  `)}
`

const MenuButton = styled(Dialog.Trigger)`
  ${fresponsive(css`
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 6px;
    -webkit-tap-highlight-color: transparent;
    width: 32px;
    height: 32px;
    z-index: 12;
    transition: translate 0.4s ${eases.cubic.inOut};

    ${Line}:nth-child(2) {
      width: 20px;
    }

    ${Line}:nth-child(3) {
      width: 27px;
    }

    &[data-state="open"] {
      translate: -20px 0;

      ${Line} {
        background: ${colors.lavender01};
        width: 25px;
      }

      ${Line}:nth-child(1) {
        rotate: -45deg;
        translate: 0 8px;
      }

      ${Line}:nth-child(2) {
        rotate: 45deg;
      }

      ${Line}:nth-child(3) {
        rotate: 45deg;
        translate: 0 -8px;
      }
    }
  `)}
`
