import { ReactComponent as ArrowSVG } from "images/global/arrow.svg"
import UniversalLink, {
	type UniversalLinkProps,
} from "library/Loader/UniversalLink"
import { fresponsive } from "library/fullyResponsive"
import styled, { css } from "styled-components"
import colors from "styles/colors"
import textStyles from "styles/text"

export default function Link({
	children,
	theme = "white",
	...props
}: UniversalLinkProps & { theme?: "white" | "blue" }) {
	return (
		<Wrapper {...props} $theme={theme}>
			<Arrow />
			{children}
		</Wrapper>
	)
}

const Arrow = styled(ArrowSVG)`
  height: auto;
  transition: transform 0.5s;

  path {
    transition: stroke 0.5s;
  }

  ${fresponsive(css`
    width: 13px;
  `)}
`

const Wrapper = styled(UniversalLink)<{
	$theme: "white" | "blue"
}>`
  display: flex;
  align-items: center;
  ${textStyles.bodyM}
  color: ${colors.silver04};
  transition: color 0.5s;
  cursor: pointer;

  ${({ $theme }) =>
		$theme === "blue" &&
		css`
      color: ${colors.brightBlue};

      path {
        stroke: ${colors.brightBlue};
        fill: ${colors.brightBlue};
      }
    `}

  &:hover {
    color: ${colors.brightGreen};

    ${Arrow} {
      transform: translateX(4px);

      path {
        stroke: ${colors.brightGreen};
        fill: ${colors.brightGreen};
      }
    }
  }

  ${fresponsive(css`
    gap: 8px;
  `)}
`
