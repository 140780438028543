import { graphql, useStaticQuery } from "gatsby"
import gsap from "gsap"
import { ReactComponent as CloseSVG } from "images/global/BannerClose.svg"
import UniversalLink from "library/Loader/UniversalLink"
import { fmobile, fresponsive, ftablet } from "library/fullyResponsive"
import useAnimation from "library/useAnimation"
import { type ComponentProps, useEffect, useRef, useState } from "react"
import Markdown from "react-markdown"
import styled, { css } from "styled-components"
import colors from "styles/colors"
import textStyles from "styles/text"

function LinkRenderer(props: ComponentProps<"a">) {
	return (
		<a {...props} target="_blank" rel="noreferrer">
			{props.children}
		</a>
	)
}

export default function Banner() {
	const banners: Queries.BannerQuery = useStaticQuery(graphql`
    query Banner {
      allContentfulAnnouncementBannerMessage(sort: { messagePriority: DESC }) {
        edges {
          node {
            id
            bannerTitle
            bannerMessage {
              bannerMessage
            }
            showMessageAt
            hideMessageAt
            messagePriority
          }
        }
      }
    }
  `)

	const bannerToShow = banners.allContentfulAnnouncementBannerMessage.edges
		.map((e) => e.node)
		.find(
			(b) =>
				// start date either undefined or is in the past
				(b.showMessageAt === null || new Date(b.showMessageAt) < new Date()) &&
				// end date is in the future
				b.hideMessageAt &&
				new Date(b.hideMessageAt) > new Date(),
		)

	const wrapper = useRef<HTMLDivElement>(null)
	const [showBanner, setShowBanner] = useState(true)

	const tween = useAnimation(
		() => {
			const bannerHeight =
				(wrapper.current?.getBoundingClientRect().height ?? 0) + 1
			return gsap.to(wrapper.current, {
				marginBottom: -bannerHeight,
				y: -bannerHeight,
				duration: 1,
				ease: "power3.inOut",
				paused: true,
			})
		},
		[],
		{
			recreateOnResize: true,
		},
	)

	useEffect(() => {
		if (showBanner) tween?.reverse()
		else tween?.play()
	}, [showBanner, tween])

	if (!bannerToShow?.bannerMessage?.bannerMessage) return null
	return (
		<FullSizeWrapper ref={wrapper}>
			<Wrapper>
				<Title>{bannerToShow.bannerTitle}</Title>
				<Copy components={{ a: LinkRenderer }}>
					{bannerToShow.bannerMessage.bannerMessage}
				</Copy>
				<Close
					type="button"
					ariaLabel="Dismiss Banner"
					onClick={() => {
						setShowBanner(false)
					}}
				>
					<CloseSVG />
				</Close>
			</Wrapper>
		</FullSizeWrapper>
	)
}

const FullSizeWrapper = styled.div`
  width: 100%;
  background: ${colors.blue01};

  ${fmobile(css`
    background: ${colors.blue05};
    padding: 18px 15px 0;
  `)}
`

const Wrapper = styled.div`
  margin: 0 auto;
  background: ${colors.blue01};
  color: ${colors.lavender05};
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${fresponsive(css`
    gap: 50px;
    max-width: 1440px;
    min-height: 64px;
    padding: 15px 50px;
  `)}

  ${fmobile(css`
    display: grid;
    place-items: center;
    text-align: center;
    gap: 6px;
    border-radius: 8px;
    padding: 16px 29px 29px;
  `)}
`

const Title = styled.div`
  ${textStyles.bodyR};
  color: ${colors.blue07};
  flex-shrink: 0;
`

const Copy = styled(Markdown)`
  ${textStyles.bodyL};
  text-wrap: balance;

  ${ftablet(css`
    ${textStyles.bodyM};
  `)}

  ${fmobile(css`
    ${textStyles.bodyM};
  `)}

  * {
    display: inline;
  }

  button,
  a {
    text-decoration: underline;
  }
`

const Close = styled(UniversalLink)`
  ${fresponsive(css`
    svg {
      display: block;
      width: 18px;
      height: 18px;
    }
  `)}

  ${fmobile(css`
    order: -1;
    place-self: end;
    translate: 14px;
  `)}
`
