import { createGlobalStyle } from "styled-components"

/**
 * place all your colors here! the format is:
 * [hex color, optional p3 color]
 *
 * if you provide a p3 color, it will be used where supported
 */
const rawColors = {
	blue01: ["#161658", "color(display-p3 0.0853 0.0853 0.3452)"],
	blue02: ["#232265", "color(display-p3 0.1373 0.1333 0.3961)"],
	blue03: ["#4846BF", "color(display-p3 0.2814 0.2735 0.7488)"],
	blue04: ["#524FD9", "color(display-p3 0.3205 0.3097 0.8493)"],
	blue05: ["#3F3CCD", "color(display-p3 0.247 0.2357 0.8028)"],
	blue06: ["#2F6BEE", "color(display-p3 0.1826 0.4187 0.9326)"],
	blue07: ["#B1C3FC", "color(display-p3 0.6941 0.7647 0.9882)"],
	lavender01: ["#5250C5", "color(display-p3 0.3222 0.3131 0.7722)"],
	lavender02: ["#6563DA", "color(display-p3 0.3961 0.3882 0.8549)"],
	lavender03: ["#876EEC", "color(display-p3 0.5307 0.4316 0.927)"],
	lavender04: ["#B1A6F6", "color(display-p3 0.6939 0.65 0.9635)"],
	lavender05: ["#E6E4FB", "color(display-p3 0.902 0.8941 0.9843)"],
	lavender06: ["#F1F1FD", "color(display-p3 0.9451 0.9451 0.9922)"],
	silver01: ["#9494A7", "color(display-p3 0.5812 0.5812 0.6552)"],
	silver02: ["#D8D8E3", "color(display-p3 0.8476 0.8476 0.8882)"],
	silver03: ["#F4F4F6", "color(display-p3 0.9568 0.9568 0.9649)"],
	silver04: ["#F4F4FB", "color(display-p3 0.9569 0.9569 0.9843)"],
	silver05: ["#FFFFFF", "color(display-p3 1 1 1)"],
	brightTurquoise: ["#72E6FF", "color(display-p3 0.446 0.9003 1)"],
	brightBlue: ["#3FAEFF", "color(display-p3 0.2482 0.6843 1)"],
	brightGreen: ["#5CFFB1", "color(display-p3 0.3608 1 0.6941)"],
} as const satisfies Record<string, [string, string] | [string]>

export const ColorStyle = createGlobalStyle`
	:root {
		@supports (not (color: color(display-p3 0 0 0))) {
			${Object.entries(rawColors).map(([key, [hex]]) => {
				return `--${key}: ${hex};`
			})}
		}

		@supports (color: color(display-p3 0 0 0)) {
			${Object.entries(rawColors).map(([key, [hex, p3]]) => {
				return `--${key}: ${p3 ?? hex};`
			})}
		}
	}
`

/**
 * convert the raw colors to an object with the correct color for the current browser
 */
const CSSColors = Object.fromEntries(
	Object.entries(rawColors as Record<string, [string, string] | [string]>).map(
		([key]) => {
			return [key, `var(--${key})`]
		},
	),
) as {
	[key in keyof typeof rawColors]: `var(--${key})`
}

/**
 * gsap can't animate variables, so we need to use the hex always
 */
const jsColors = Object.fromEntries(
	Object.entries(rawColors as Record<string, [string, string] | [string]>).map(
		([key, [color]]) => {
			return [key, color]
		},
	),
) as {
	[key in keyof typeof rawColors]: (typeof rawColors)[key][0]
}

export default {
	...CSSColors,
	js: jsColors,
}
