import { fresponsive } from "library/fullyResponsive"
import { css } from "styled-components"

const textStyles = {
	h1: fresponsive(css`
    font-family: Gilroy, sans-serif;
    font-size: 124px;
    font-style: normal;
    font-weight: 600;
    line-height: 92%; /* 114.08px */
    letter-spacing: -4.96px;
  `),
	h2: fresponsive(css`
    font-family: Gilroy, sans-serif;
    font-size: 92px;
    font-style: normal;
    font-weight: 600;
    line-height: 92%; /* 84.64px */
    letter-spacing: -3.68px;
  `),
	h3: fresponsive(css`
    font-family: Gilroy, sans-serif;
    font-size: 46px;
    font-style: normal;
    font-weight: 600;
    line-height: 92%; /* 42.32px */
    letter-spacing: -1.84px;
  `),
	h4: fresponsive(css`
    font-family: Gilroy, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 92%; /* 22.08px */
    letter-spacing: -0.96px;
  `),
	h5: fresponsive(css`
    font-family: Gilroy, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 144%; /* 20.16px */
    letter-spacing: -0.56px;
  `),
	bodyXL: fresponsive(css`
    font-family: Gilroy, sans-serif;
    font-size: 42px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; /* 54.6px */
    letter-spacing: -1.68px;
  `),
	bodyL: fresponsive(css`
    font-family: Gilroy, sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 17px */
    letter-spacing: -0.51px;
  `),
	bodyR: fresponsive(css`
    font-family: Gilroy, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 144%; /* 25.92px */
    letter-spacing: -0.72px;
  `),
	bodyM: fresponsive(css`
    font-family: Gilroy, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 144%; /* 20.16px */
    letter-spacing: -0.56px;
  `),
	bodyS: fresponsive(css`
    font-family: Gilroy, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 144%; /* 20.16px */
    letter-spacing: -0.56px;
  `),
	bodyXS: fresponsive(css`
    font-family: Gilroy, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 92%; /* 11.04px */
    letter-spacing: -0.48px;
  `),
	kickerR: fresponsive(css`
    font-family: Gilroy, sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 144%; /* 21.6px */
    letter-spacing: 0.3px;
    text-transform: uppercase;
  `),
	kickerS: fresponsive(css`
    font-family: Gilroy, sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 144%; /* 18.72px */
    letter-spacing: 0.26px;
    text-transform: uppercase;
  `),
}

export default textStyles
